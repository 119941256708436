import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";

class App extends Component {
  state = { count: 0 };
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>PWA on Netlify</p>
          <button
            style={{ width: "50%", height: 100 }}
            onClick={e => {
              this.setState({ count: this.state.count + 1 });
              e.preventDefault();
            }}
          >
            Tryck
          </button>
          <p>{this.state.count}</p>
        </header>
      </div>
    );
  }
}

export default App;
